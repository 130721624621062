body {
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  font-family: 'Lato', sans-serif;
  font-size: 92%;
  color: #666; }
  body header {
    margin: 10px 0 20px 0;
  }

h1, h3, h4, h5, h6 {
  color: #0066b9; }
h2 {
 color:#666;
 text-transform: uppercase;
 font-weight:900;
}

h5 small {
  font-size: .65em;
  float: right;
  font-weight: 500; }
h5 span {
  color:#999;
  font-size: .65em;
  font-weight:400;
}



strong i.fa {
  font-size: 1.4em;
  margin-right: 5px; }

ul.highlights {
  transition: display 0s, opacity 1s linear;
}

.profile img {
  max-width: 110px;
  max-height: 110px;
 }



.heading {margin-left:5px}
.heading {
  font-weight: 800;
  padding: 15px;
}

.date-exp {
  text-align: right;
  font-size: .95em;
  color: #aaa; }
.logos {
  max-width:100px
}
h1.new-heading {
  color: #0066b9;
  font-size:4em;
  font-family: 'Alfa Slab One', cursive;
  padding-bottom:0;
  margin-bottom: 0;
}
.parent {
  position:relative;
}
.pad-left {
  padding-left: 30px;
}
.pad-left-15 {
  padding-left: 15px;
}

.head-90 {
  display:inline-block;
  color:#666;
  font-weight:900;
  position:absolute;
  font-size: 1rem;
  left:-40px;
  top: 38px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);

}


.entry {
  margin:15px 5px
}
.entry h5 {
  font-weight:800;
}
.entry h6 {
  margin:10px 0 0 0;
}
.entry h4.head-90 {
  left:-40px;
  top: 18px;
}
.button-m {
  margin:20px;
}


.sidebar {
  background-color: rgb(243, 246, 249);
  border-radius: 8px;
  font-size: .9em; }

  @media only screen and (max-width: 992px) {

    header {
      padding:15px;
    }
    body header h1.new-heading {
      font-size:2.4em;
    }
    h5 span {display:block}
    .profile img {
      max-width: 75px;
      max-height: 75px;
    }
    body header h2 {
      font-size: 1.3em;
    }

    .sidebar {
      border-radius: 0; }
   }

.btn-outline-primary:focus {
    box-shadow: none; 
}
